@import "../node_modules/@fullcalendar/daygrid/main.min.css";
@import "../node_modules/@fullcalendar/timegrid/main.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";
/* You can add global styles to this file, and also import other style files */

@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/prismjs/themes/prism-coy.css";
@import "assets/demo/flags/flags.css";

:root {
    --error-border-color: #f44336;
}

//scroll p-overlayPanel prime
.p-overlaypanel-content {
    overflow-y: scroll;
    max-height: 400px;
}

$validacao-erro: #f44336;
$titulo-identificacao-conta: blue;
$cor-laranja: #f97f39;
$cor-verde: #078261;

.botao-adicionar {
    background: $cor-verde  !important;
    border: $cor-verde  !important;
}

.botao-pesquisa {
    background: $cor-laranja  !important;
    border: $cor-laranja  !important;
}

.erro-validacao {
    color: $validacao-erro;
}

.titulo-configuracao-conta {
    text-transform: capitalize;
    color: $titulo-identificacao-conta;
    padding: 0;
    border: 0;
    padding-bottom: 0;
}

.float-btn-next {
    float: right;
}

.time-line {
    cursor: pointer;
}

.cursor-pointer:hover {
    cursor: pointer;
}

.selecao:hover {
    cursor: pointer;
    background-color: aliceblue;
}

.saldo-negativo {
    color: red !important;
}

.swal2-container {
    z-index: 500000 !important;
}

.conta-corrente .p-field {
    flex: 1 1 20%;
}


@media (max-width: 768px) {
    .conta-corrente .p-field {
        flex: 1 1 50%;
    }
}

@media (max-width: 480px) {
    .conta-corrente .p-field {
        flex: 1 1 100%;
    }
}
